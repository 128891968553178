import React, { useEffect, useState } from "react";
import { getPeriodCampaignImpressionsAndClicks } from "../../../services/micro";
import { Card, Placeholder, Col, Nav, Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useCookiesContext, CookiesKeyNames } from "../../../contexts/CookiesContext";
import { subDays } from "date-fns";
import { formatThousand } from "../../../helpers/thousand-number-format";
import empty_state_clicks_iframe_icon from "../../../assets/svg/empty_state_clicks_iframe_icon.svg";
import { getCampaign } from "../../../services/adv";

function getCSSVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
}

export default function CampaignClicks(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const campaignId = urlParams.get("campaignId");

  const [currentPeriod, setCurrentPeriod] = useState([subDays(new Date(), 30), new Date()]);
  const [campaign, setCampaign] = useState(null);
  const [response, setResponse] = useState([]);
  const [colors, setColors] = useState({
    primary: "",
    chartSecondary: "",
    columnChart: "",
  });

  const { cookiesData } = useCookiesContext();
  const publisherId = cookiesData[CookiesKeyNames.publisherId];

  useEffect(() => {
    const primary = getCSSVariableValue('--chart-color-3');
    const chartSecondary = getCSSVariableValue('--chart-color-2');
    const columnChart = getCSSVariableValue('--chart-color-1');
    setColors({ primary, chartSecondary, columnChart });
  }, []);

  useEffect(() => {
    if (currentPeriod) {
      getPeriodCampaignImpressionsAndClicks(
        currentPeriod,
        Number(campaignId),
        token
      ).then((response) => {
        setResponse(response);

        getCampaign(campaignId, token).then((campaign) => {
          setCampaign(campaign.data);
        });
      });
    }
  }, [props]);

  const optionFour = {
    chart: {
      parentHeightOffset: 0,
      stacked: false,
      toolbar: { show: false },
      animations: {
        enabled: true,
      },
      type: "line", 
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        bottom: -20,
        left: 20,
        right: 0,
      },
    },
    colors: [colors.columnChart, colors.chartSecondary, colors.primary], 
    plotOptions: {
      bar: { columnWidth: "50%" },
    },
    stroke: {
      curve: ["smooth", "smooth", "smooth"], 
      width: [1.5, 1.5, 2], 
    },
    fill: {
      type: ["solid", "gradient", "solid"],
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 0.2,
        stops: [0, 100],
        gradientToColors: ["#f3f5fc"], 
      },
    },
    xaxis: {
      tickAmount: 15,
      labels: {
        style: {
          colors: "#ccc",
          fontSize: "11px",
        },
        rotate: -45,
        rotateAlways: true, 
      },
    },
    yaxis: [
      {
        seriesName: "Clicks",
        opposite: true,
        labels: {
          show: false, 
        },
      }
    ],
    dataLabels: { enabled: false },
    tooltip: {
      shared: true,
      intersect: false
    },
    legend: { show: true },
  };

  const openCampaign = () => {
    window.open(`https://hoteltechreport.com/dashboard/${campaign?.external_id}/ppc#ppc-auction`, "_blank");
  };

  if (response && response.stats) {
    response.stats = [response.stats[0]];

    const categories = response.dates;
    optionFour.xaxis.categories = categories;
    optionFour.xaxis.tickAmount = 15;

    const totalClicks = response.stats[0].data.reduce((a, b) => a + b, 0);

    // check if the any item of data is 0
    if (response.stats[0].data.every((item) => item === 0)) {
      // last 30 dates
      let empty_state_categories = [];

      for (let i = 0; i < 30; i++) {
        empty_state_categories.push(new Date(
          subDays(new Date(), i)
        ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
      }

      optionFour.xaxis.categories = empty_state_categories

      return (
        <React.Fragment>
          <Col md="12" xl="12">
            <Card className="card-one" style={{ 
              borderColor: '#E9E9ED', 
              shadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',  
              borderRadius: '8px',
              padding: '0px',
              height: '387px',
              border: '1px solid #E9E9ED',
              backgroundColor: '#FFFFFF',
            }}>
              <Card.Body style={{ padding: '0px' }}>
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  height: '340px',
                  width: '100%',
                  position: 'absolute',
                }}>
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      width: '350px',
                      height: '200px',
                      border: '1px solid #E9E9ED',
                      zIndex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={empty_state_clicks_iframe_icon} alt="Empty state" style={{ width: '100px', height: 'auto' }} />
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                      <h4 style={{ color: '#71717A' }}>No clicks in the last 30 days</h4>
                    </div>
                    <Button variant="primary" style={{ marginTop: '10px' }} onClick={openCampaign}>
                      Activate Campaign
                    </Button>
                  </div>
                </div>
                <div style={{ padding: '24px 24px 0px 24px' }}>
                  <h5 style={{ color: '#71717A', fontSize: '14px', fontWeight: '500' }}>Clicks (30d)</h5>
                  <h3 class="mb-0 mt-1" style={{ color:'#18181B', fontSize: '20px', fontWeight: '400' }}>
                    0
                  </h3>
                </div>
                <div className="w-100" style={{ padding: '0px 10px 10px 0px' }}>
                  <ReactApexChart
                    series={[{ name: 'Clicks', type: 'bar', data: Array(30).fill(0) }]}
                    options={optionFour}
                    type="bar"
                    height={300}
                    style={{ margin: '0 0 0 0', paddingLeft: '-10px', paddingRight: '-10px' }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment style={{ 
        backgroundColor: 'rgba(232, 234, 237)',
      }}>
        <Col md="12" xl="12">
          <Card className="card-one" style={{ 
            borderColor: '#E9E9ED', 
            shadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',  
            borderRadius: '8px',
            padding: '0px',
            height: '387px',
            border: '1px solid #E9E9ED',
            backgroundColor: '#FFFFFF',
          }}>
            <Card.Body style={{ padding: '0px' }}>
              <div style={{ padding: '24px 24px 0px 24px' }}>
                <h5 style={{ color: '#71717A', fontSize: '14px', fontWeight: '500' }}>Clicks (30d)</h5>
                <h3 class="mb-0 mt-1" style={{ color:'#18181B', fontSize: '20px', fontWeight: '400' }}>
                  {formatThousand(totalClicks)}
                </h3>
              </div>
              <div className="w-100" style={{ padding: '0px 10px 10px 0px' }}>
                <ReactApexChart
                  series={response.stats}
                  options={optionFour}
                  type="bar"
                  height={300}
                  style={{ margin: '0 0 0 0', paddingLeft: '-10px', paddingRight: '-10px' }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 387, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
