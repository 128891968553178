import { toast } from "react-toastify";
import { validateVatNumber } from "../services/micro";
import { getPublisher } from "../services/pub";
import getAllCountires from "../services/adv/get_all_countires";
import { CookiesKeyNames, isCookiesEnabled } from "../contexts/CookiesContext";
import Cookies from "universal-cookie";

export default async function validateForRequired(tableStructure, newRecord, isManualTransactionCreation) {
  const cookies = new Cookies();
  const validationResults = {};

  await Promise.all(tableStructure.columns.map(async (column) => {
    
    if (isManualTransactionCreation && (column.name === "user_email" || column.name === "first_name" || column.name === "last_name" || column.name === "invoice_account_name" || column.name === "invoice_address" || column.name === "invoice_street" || column.name === "invoice_city" || column.name === "invoice_address_zip_code" || column.name === "invoice_address_state" || column.name === "invoice_address_country_id" || column
      .name === "vat_number")) {
      return;
    }

    if (column.is_required && (newRecord[column.name] === undefined || newRecord[column.name] === null || newRecord[column.name] === 0 || newRecord[column.name] === '')) {
      toast.error(`Error while creating record: ${column.title} is required!`);
      validationResults[column.name] = false;
    } else {
      validationResults[column.name] = true;
    }

    if (column.name === 'vat_number' || column.name === 'billing_vat_number') {
      if (!newRecord[column.name]) {
        validationResults[column.name] = true;
        return;
      }

      if (newRecord[column.name].trim().length === 0) {
        validationResults[column.name] = true;
        return;
      }

      const publisherIdByCookies = isCookiesEnabled ? cookies.get(CookiesKeyNames.publisherId) : localStorage.getItem(CookiesKeyNames.publisherId);
      const publisher = await getPublisher(publisherIdByCookies);
      const countries = await getAllCountires();

      const country = countries?.data?.find(country => country.id === publisher?.data?.country_id);

      if (!country) {
        validationResults[column.name] = false;
        return;
      }

      const vatValidation = await validateVatNumber({ countryCode: country?.code, vatNumber: newRecord[column.name] });
      
      if (vatValidation?.data?.isVatValid) {
        validationResults[column.name] = true;
      } else {
        validationResults[column.name] = false;
      }
    }
  }));

  return validationResults;
}