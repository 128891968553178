import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { setUser } from "../features/user";
import { getMe } from "../services/micro";
import { loadersModule } from "../modules/loaders";

import { getPublisher } from "../services/pub";
import { toast } from "react-toastify";
import { mixpanelInstance } from "../mixpanel/instance";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

const handleMixpanelTracking = (user) => {
  try {
    mixpanelInstance.people.set(user.id, {
      $firstname: user.first_name,
      $lastname: user.last_name,
      $email: user.email,
      $role: user.role,
      $publisherId: user.publisher_id,
    });
    mixpanelInstance.track("Sign Up", { "Signup Type": "Login" });
  } catch (error) {
    console.error("Error in Mixpanel people.set:", error);
  }
};

export const Auth = ({ children }) => {
  const { updateCookiesProperty, cookiesData, getPathKey } =
    useCookiesContext();

  const [isLoading, setIsLoading] = useState(true);
  const [publisherId, setPublisherId] = useState(null);
  const [storedPublisherId, setStoredPublisherId] = useState(null);

  const dispatch = useDispatch();
  const storedUser = useSelector((state) => state.user);
  const navigate = useNavigate();

  const advertiserId =
    cookiesData[getPathKey()]?.[CookiesKeyNames.advertiserId];
  const publisherIdByCookies =
    cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

  useEffect(() => {
    const publisherIdByAbbr = GetPubAbbr();
    const publisherId = publisherIdByCookies || publisherIdByAbbr;

    setPublisherId(publisherId);
    setStoredPublisherId(publisherIdByCookies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const logoGif = loadersModule(publisherId);

  const fetchUser = async () => {
    // each time
    try {
      const handlePublisherResponse = async (publisherId) => {
        if (!publisherId) return;

        // const publisherResponse = await getPublisher(publisherId);

        // if (publisherResponse.success) {
        //   const abbreviation = publisherResponse.data.abbreviation;
        //   updateCookiesProperty(CookiesKeyNames.abbreviation, abbreviation);
        // } else {
        //   toast.error("Cannot get publisher");
        // navigate("/pages/signin"); // publisher case (withoud advId)
        // }
      };

      // Publisher case
      if (storedPublisherId) {
        return await handlePublisherResponse(storedPublisherId);
      }

      // No data in cookies
      else {
        let response = null;

        // Advertiser case
        if (advertiserId) {
          console.log("advertiserId", advertiserId);
          response = await getMe(null, advertiserId);
        }

        // Publisher case
        else {
          response = await getMe();
        }

        if (!response) {
          console.error("#1 Error fetching user data");
          // navigate("/pages/signin");
          return;
        }
        if (!response.success || !response.data) {
          console.error("#2 Error fetching user data");
          // navigate("/pages/signin");
          return;
        }

        console.log("response.data", response?.data);

        const user = response.data;

        handleMixpanelTracking(user);

        dispatch(setUser(user));
        updateCookiesProperty(CookiesKeyNames.publisherId, user.publisher_id);
        await handlePublisherResponse(user.publisher_id);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      // navigate("/pages/signin");
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      // setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    fetchUser();
  }, [window.location.pathname]);

  if (isLoading) {
    if (publisherId) {
      return <Form className="page-sign dark">{logoGif}</Form>;
    } else {
      return <></>;
    }
  } else {
    return <>{children}</>;
  }
};
